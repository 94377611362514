import "./Section_3.scss";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../../../firebase/config";


export const Section3 = ({setLoading, setError, setIdInfo, setIsWindow}) => {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'fKcLWaEjXJIi1PQE77Q4');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);

    const handleButtonClick = (id) => {
        setIdInfo(id)
        setIsWindow(true)
    };
    
    return (
        <section className="section-3">
            <div className="section-3-contect-container">
                <div className="section-3-contect-container-left">
                    <h2 className="title-page-3" dangerouslySetInnerHTML={{ __html: info.title }}></h2>
                    <div className="section-3-contect-container-left-card">
                        {info.card&&
                            <>
                                {info.card.map((item) => (
                                    <div className="info-section-3-card">
                                        <img className="section-3-card-img" src={item.img} alt={item.title}/>
                                        <h2 className="title-page-4" dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                                        <p className="subtitle-page-3" dangerouslySetInnerHTML={{ __html: item.subtitle}}></p>
                                        <button className="button-3-card"  onClick={() => handleButtonClick(item.id)}>Descubre más</button>
                                    </div>
                                ))}
                            </>
                        }
                    </div>
                </div>
                <div className="section-3-contect-container-right">
                    <img src={info.img} alt="Shofi"/>
                </div>
            </div>
        </section>
    );
};