/* eslint-disable react-hooks/exhaustive-deps */
import "./Form_Register.scss";
import { db } from '../../../../firebase/config';
import React, { useState, useCallback, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { BiCheckCircle } from "react-icons/bi";
import { toast } from "react-toastify";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500},
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendRegister"; 

async function sendRegister(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            toast.success("Email enviado con exito");
        } else {
            toast.error("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            toast.error("Error body:", errorBody);
        }
    } catch (error) {
        toast.error("Error de envio email:", error);
    }
}

export const FormRegisterCatalogs = ({toggleRe}) => {
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        load: '',
        numbemploye:'',
        date: new Date(),
    });


    useEffect(() => {
        const storedSubmittedData = localStorage.getItem('submitted');
        if (storedSubmittedData) {
            const { value, expiry } = JSON.parse(storedSubmittedData);
            const now = new Date();
            if (now.getTime() > expiry) {
                localStorage.removeItem('submitted');
                setSubmitted(false);
            } else {
                setSubmitted(value);
            }
        }
    }, []);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    const validateEmail = useCallback((email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }, []);

    const validatePhone = useCallback((phone) => {
        const regex = /^[0-9]*$/;
        return regex.test(phone);
    }, []);

    const handlePhoneChange = useCallback((e) => {
        const value = e.target.value;
        if (validatePhone(value) || value === '') {
            handleChange(e);
        }
    }, [handleChange, validatePhone]);

    const toggleClose = () => {
        navigate('/subscripcion/true'); // Inicia la navegación
        gtag_report_conversion("https://calendar.app.google/KoFUie995NoYYGJY8");
    };

    // Datos de Hubspot
    const HUBSPOT_PORTAL_ID = '44612660';
    const HUBSPOT_FORM_ID = '73e38c62-5524-47b1-b2d5-d985751714f0';
    const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_ID}`;

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!recaptchaValue) {
            toast.error('Por favor, verifica el reCAPTCHA');
            return;
        }
        if (validateEmail(formData.email) && validatePhone(formData.phone)) {
            const hubspotFormData = {
                fields: [
                    { name: "email", value: formData.email },
                    { name: "firstname", value: formData.name },
                    { name: "phone", value: formData.phone },
                    { name: "company", value: formData.company },
                    { name: "jobtitle", value: formData.load}
                ],
                context: {
                    pageUri: window.location.href,
                    pageName: "Registro Web SHOFI"
                }
            };
            try {
                await addDoc(collection(db, "form-cita"), formData);
                await sendRegister(formData);
                const response = await fetch(HUBSPOT_ENDPOINT, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(hubspotFormData),
                });
                if (!response.ok) throw new Error("HubSpot submission error");

                // Enviar evento a Google Tag Manager para el formulario de registro
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'registrationFormSubmit',
                    form: {
                        name: formData.name,
                        email: formData.email,
                        company: formData.company,
                        phone: formData.phone,
                        load: formData.load,
                        numbemploye: formData.numbemploye,
                        formType: 'Registro SHOFI'
                    }
                });

                setFormData({
                    name: '',
                    company: '',
                    email: '',
                    phone: '',
                    load: '',
                    numbemploye: '',
                    date: new Date(),
                });
                setSubmitted(true);
                const expiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
                localStorage.setItem('submitted', JSON.stringify({ value: true, expiry: expiryTime }));
                toast.success("Registro exitoso.");
                toggleClose();
            } catch (error) {
                toast.error('Error: ' + error.message);
            }
        } else {
            toast.error('Datos no válidos');
        }
    }, [formData, recaptchaValue]);

    return (
        <div className='container-register'>
            <FadeInSection>
                <div className="brand" >
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                </div>
                <h1 className="subtitle-page-1">Agenda una cita con nuestros<br/>
                asesores expertos en <span>trabajo en casa</span></h1>
                {!submitted && <form onSubmit={handleSubmit} className="form-contact">
                    <div className='input-date-form'>
                        <label>Nombre:</label>
                        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className='input-date-form'>
                        <label>Cargo:</label>
                        <select name="load" value={formData.load} onChange={handleChange} required>
                            <option value="" disabled>Seleccione</option>
                            <option value="Director">Director</option>
                            <option value="CEO">CEO</option>
                            <option value="RRHH">RRHH</option>
                            <option value="SST">SST</option>
                            <option value="Financiero">Financiero</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                    <div className='input-date-form'>
                        <label>Empresa:</label>
                        <input type="text" name="company" value={formData.company} onChange={handleChange} required />
                    </div>
                    <div className='input-date-form'>
                        <label>Número de empleados:</label>
                        <select name="numbemploye" value={formData.numbemploye} onChange={handleChange} required>
                            <option value="" disabled>Seleccione</option>
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-100">51-100</option>
                            <option value="101-10">101-500</option>
                            <option value="501-2000">501-2000</option>
                            <option value="+2000">+2000</option>
                        </select>
                    </div>
                    <div className='input-date-form'>
                        <label>Teléfono:</label>
                        <input type="text" name="phone" value={formData.phone} onChange={handlePhoneChange} required />
                    </div>
                    <div className='input-date-form'>
                        <label>Email:</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className='recaptcha'>
                        <ReCAPTCHA
                            sitekey="6LdgvfwnAAAAAEIbajmXn1qZoeHC4gkRsPHKDond"
                            onChange={value => setRecaptchaValue(value)}
                            style={{ transform: 'scale(0.68)', transformOrigin: '0 0', width: '202px',}}
                        />
                    </div>
                    <button className='button-form' type="submit" onclick="return gtag_report_conversion('https://calendar.app.google/KoFUie995NoYYGJY8')">Agenda tu cita</button>
                </form>}
                {submitted &&
                    <div className="exict-form">
                        <BiCheckCircle />
                        <p className="subtitle3 succeso">Enviado con éxito</p>
                    </div>
                }
            </FadeInSection>
        </div>
    );
}