import "./PopupWindowInfo.scss";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { db } from "../../../../firebase/config";
import ReCAPTCHA from "react-google-recaptcha";
import { HiMiniArrowLongRight } from "react-icons/hi2";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { BiX } from "react-icons/bi";
import { toast } from "react-toastify";
// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500},
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendRegisterEmail"; 

async function sendRegister(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            toast.success("Email enviado con exito");
        } else {
            toast.error("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            toast.error("Error body:", errorBody);
        }
    } catch (error) {
        toast.error("Error de envio email:", error);
    }
}

export const PopupWindowInfo = ({id, setIsWindow}) => {
    const [info, setInfo] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        register: '',
        id_form:'',
    });

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    useEffect(() => {
        const storedSubmittedData = localStorage.getItem('submitted_email');
        if (storedSubmittedData) {
            const { value, expiry } = JSON.parse(storedSubmittedData);
            const now = new Date();
            if (now.getTime() > expiry) {
                localStorage.removeItem('submitted_email');
                setSubmitted(false);
            } else {
                setSubmitted(value);
            }
        }
    }, []);


    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page_info', id);
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    console.log(err);
                }
            })
        return () => { isMounted = false; };
    }, [id]);

    // Datos de Hubspot
    const HUBSPOT_PORTAL_ID = '44612660';
    const HUBSPOT_FORM_ID = '73e38c62-5524-47b1-b2d5-d985751714f0';
    const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_ID}`;

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!recaptchaValue) {
            toast.error('Por favor, verifica el reCAPTCHA');
            return;
        }
        if (validateEmail(formData.email)) {
            const hubspotFormData = {
                fields: [
                    { name: "email", value: formData.email }
                ],
                context: {
                    pageUri: window.location.href,
                    pageName: `Registro Info: ${info.title}`
                }
            };
            try {
                const NewformData = {
                    ...formData,          
                    register: info.title,  
                    id_form: id            
                };
                const registro = collection(db, "registro_info");
                await addDoc(registro, {
                    ...NewformData 
                });
                await sendRegister(NewformData);
                const response = await fetch(HUBSPOT_ENDPOINT, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(hubspotFormData),
                });
                if (!response.ok) throw new Error("HubSpot submission error");
                setFormData({
                    email: '',
                    register: '',
                    id_form: '',
                });
                setSubmitted(true); 
            } catch (error) {
                console.error("Error guardando datos en Firebase: ", error);
            }
        } else {
            console.log('Datos no válidos');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, recaptchaValue]);

    const validateEmail = useCallback((email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }, []);

    const toggleClose = () => {
        setIsWindow (prevIsLogin=> !prevIsLogin);
    };

    return (
        <div className="main-info-section">
            <FadeInSection>
                <button onClick={toggleClose} className='button-x-flotant'>
                    <BiX />
                </button>
                <div className="register-demo-info-3">
                    <section className='container-register-demo-info-3'>
                        <div className="image-container-3">
                            <img className="section-3-card-img" src={info.img}  alt={info.title}/>
                        </div>
                        <div className="container-info-3">
                            <div className="container-register-demo-info-3-title">
                                {info.title&&<h2 dangerouslySetInnerHTML={{ __html: info.title}} className="title-page-5"></h2>}
                                {info.icon&&<img className="container-register-demo-info-3-title-img" src={info.icon} alt={info.title}/>}
                            </div>
                            {info.descripcion&&<h3 dangerouslySetInnerHTML={{ __html: info.descripcion}} className="subtitle-page-3"></h3>}
                            {info.card&&
                                <div className="container-register-demo-info-3-grill">
                                    {info.card.map((item) => (
                                        <div className="container-register-demo-info-3-card">
                                            <img className="container-register-demo-info-3-card-img" src={item.icon} alt={item.title}/>
                                            <p className="subtitle-page-3" dangerouslySetInnerHTML={{ __html: item.title}}></p>
                                        </div>
                                    ))}
                                </div>
                            }
                            {info.contect&&<h3 className="subtitle-page-3" dangerouslySetInnerHTML={{ __html: info.contect}}></h3>}
                            {info.subtitle&&<h3 className="title-page-6" dangerouslySetInnerHTML={{ __html: info.subtitle}}></h3>}
                            {info&&
                                <>
                                    {!submitted 
                                        ?<>
                                            <form onSubmit={handleSubmit} className="form-info-3">
                                                <div className='recaptcha'>
                                                    <ReCAPTCHA
                                                        sitekey="6LdgvfwnAAAAAEIbajmXn1qZoeHC4gkRsPHKDond"
                                                        onChange={value => setRecaptchaValue(value)}
                                                        style={{ transform: 'scale(0.67)', transformOrigin: '0 0', width: '200px',}}
                                                    />
                                                </div>
                                                <div className='input-info-3'>
                                                    <input placeholder="Correo Electrónico" type="email" name="email" value={formData.email} onChange={handleChange} required />
                                                    <button className='button-form-info-3' type="submit"><HiMiniArrowLongRight /></button>
                                                </div>
                                            </form>
                                        </>
                                        :<div>
                                            <br></br>
                                            <h3 className="title-page-2">¡Registro Exitoso!</h3>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </section>
                </div>
            </FadeInSection>
        </div>
    );
};
