import { deleteDoc,doc,getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from '../../../../firebase/config';
import { InfoLoading } from "../../../../Loanding";
import { BiX } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { MdOutlineDeleteOutline, MdOutlineEmail, MdWhatsapp } from "react-icons/md";

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/createUserOpen"; 

async function createUserOpen(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            toast.success("Email enviado con exito");
        } else {
            toast.error("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            toast.error("Error body:", errorBody);
        }
    } catch (error) {
        toast.error("Error de envio email:", error);
    }
}

export const EditorUserOpen = ({setIsEditorOpen, selectedId, setRetur}) => {
    const navigate = useNavigate(); 
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ formData, setFormData ] = useState({
        answer:false,
        cc: "",
        company:"",
        date:"",
        email: "",
        height: "",
        idc: "",
        ide:"",
        ider:"",
        lastname:"",
        name:"",
        phone:"",
        sex:"",
        status:"",
        weight:"",
        year:"",
        pais:'Colombia',
        department: "",
        city: "",
        address: "",
        address2: "",
    });

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!selectedId) throw new Error("Id Foil is undefined");
                const docRef = doc(db, 'users_open', selectedId);
                const docData = await getDoc(docRef);
                if (docData.exists() && isMounted) {
                    const userData = docData.data();
                    setFormData({ ...formData, ...userData });
                }                
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => {isMounted = false;}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);   

    const toggleRegister = () => {
        setIsEditorOpen(false);
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            await createUserOpen({ ...formData, id:selectedId});
            toast.success(`Invitación enviada correctamente: ${formData.email}`);
            navigate('/open/invitations');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Error creando el documento: " + error.message);
        }
    };

    // Borrado de usuario
    const deleteSubmit = async (e) => {
        e.preventDefault();  // Evita que se recargue la página
        setLoading(true);
        try {
            const db = getFirestore();
            // Eliminar el usuario
            const userDocRef = doc(db, 'users_open', selectedId);
            await deleteDoc(userDocRef);
            navigate('/open/invitations');
            setRetur(prevIsOpenR => !prevIsOpenR);
            setIsEditorOpen(false);
            toast.success('Todos los datos fueron borrados');
        }  finally {
            setLoading(false);
        }
    };

    //Compartir encuesta a través de WhatsApp
    const shareSubmit = async (e) => {
        e.preventDefault();  // Evita que se recargue la página
        try {
            const surveyUrl = `https://goshofi.com/quiz/rest/${formData.idc}/${formData.ide}/${selectedId}`; // URL de la encuesta que deseas compartir
            const message = `¡Hola! Te invito a responder esta encuesta diagnóstica de tu puesto de trabajo: ${surveyUrl}`;
            // Crear el enlace de WhatsApp con el mensaje
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
            // Abrir el enlace de WhatsApp
            window.open(whatsappUrl, "_blank");
        } catch (error) {
            console.error("Error al compartir encuesta: ", error);
        }
    };


    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="container-quiz-open">
            <button onClick={toggleRegister} className='button-x-close'>
                <BiX />
            </button>
            <div className='section-log'>
                <div className="quiz-open-users">
                    <div className="brand" >
                        <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                    </div>
                    <h2 className="title-page-2">Enviar una nueva <span>encuesta</span></h2>
                    <form onSubmit={handleSubmit} className="form-open-users">
                        <div className='input-date-open'>
                            <label >Nombre:</label>
                            <input type="text" name="name" value={formData.name} readOnly/>
                        </div>
                        <div className='input-date-open'>
                            <label>Apellido:</label>
                            <input type="text" name="lastname" value={formData.lastname}  readOnly/>
                        </div>
                        <div className='input-date-open'>
                            <label>Email:</label>
                            <input type="email" name="email" value={formData.email}  readOnly/>
                        </div>
                        <button  className="button-new  space-bottom"  type="submit">
                            <MdOutlineEmail /> Reenviar Email 
                        </button>
                        <button  className="button-whtaspp  space-bottom" onClick={shareSubmit}>
                            <MdWhatsapp /> Compartir 
                        </button>
                        <button  className="button-delete  space-bottom" onClick={deleteSubmit}>
                            <MdOutlineDeleteOutline /> Borrar Cuenta
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};