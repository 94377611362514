import "./Pl_Section_2.scss";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../../firebase/config";

export const PlSection2 = ({setLoading})  => {
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'aYQuGvbGswdhnY0LBRfd');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);

    if (error) return <p>Error: {error.message}</p>;
    return (
        <section className="section-contect">
            <div class="ellipse-div-2"></div>
            <div className="section-2Pl-contect-container">
                <div className="section-2Pl-title">
                    <div className="section-2Pl-icon">
                        <img alt={info.title} src={info.icon} className="icon-2Pl"/>
                    </div>
                    <section className="section-2Pl-title-contect">
                        <h1 className="title-page-2pl" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                        <p className="subtitle-page-2pl" dangerouslySetInnerHTML={{ __html: info.contect }}></p>
                    </section>
                </div>
                <div className="section-2Pl-img">
                    <img alt={info.title} src={info.img} className="img-2Pl"/>
                </div>
            </div>
        </section>
    );
}
