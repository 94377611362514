import { InfoLoading } from "../../../../Loanding";
import { Link } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
import SendUsersOpen from "../../../UI/molecules/SendUsersOpen/SendUsersOpen";
import { useState } from "react";

export const CreadorUserCvs = ({ idc , company , ide }) => {
    const [loading, setLoading] = useState(false);
    const csvURL = "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fplantilla%2FPlantillaUserShofi.csv?alt=media&token=9a6ab531-44b0-4f8c-bcd5-fde5db0c95d4";

    if (!idc ) return <InfoLoading/>;
    return (
        <div className="windons-user">
            <div className="encabezado-list">
                <h2 className="title">Equipo: {company}</h2>
                <Link className='button-new' to="/open/invitations">
                    <BiChevronLeft /> Volver
                </Link>
            </div>
            <div className="windons-quiz-init">
                <div className="section-quiz-init">
                    <div className="quiz-init-img">
                        <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.."/>
                        <h2 className="title-shofi-loanding">Este método es mas rápido<br></br>crea usuarios de manera rápida.</h2>
                    </div>
                    {!loading
                        ?<div className="quiz-init-contect">
                            <h3 className="subtitle2  flex-section">Carga de forma masiva usando esta Plantilla CSV:<br></br> Descarga modelo de carga
                            <a
                                href={csvURL}
                                download="cargamasiva.csv"
                                className="button-download"
                                rel="noopener noreferrer"
                            >
                                Plantilla CSV
                            </a>
                            </h3>
                            <SendUsersOpen
                                idc = {idc}
                                ide = {ide}
                                company = {company}
                                setLoading={setLoading}
                            />
                        </div>
                        :<div className="contect-carga-datos">
                            <h3 className="subtitle2  flex-section">Cargando datos y enviando notificaciones <br></br>esto puede tardar un poco</h3>
                            <div className="loading-circle"></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}