import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { useEffect, useState, useMemo } from "react";
import { ListUsersAprove } from "../../../UI/organisms/ListOrderAprove/ListOrderAprove";
import { debounce } from "lodash";

export const UpdateOrden = () => {
    const itemsPerPage = 20;
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState('nombre');
    const [sortDirection, setSortDirection] = useState('asc');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [company, setCompany] = useState([]);
    const [selectCompany, setSelectCompany] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    
    
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'company');
                const querySnapshot = await getDocs(q);
                    
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                    
                if (isMounted) setCompany(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);   


    useEffect(() => {
        const fetchInfo = async () => {
            let isMounted = true;
            try {
                setLoading(true);
                const q = collection(db, 'orden');
                const u = query(q, where('order_acceptance', '==', true));
                const querySnapshot = await getDocs(u);
                const fetchedInfo = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data,
                        ...data.deleveryData
                    };
                });
    
                let filteredUsers = fetchedInfo;
    
                // Filtro por fechas
                if (startDate) {
                    const start = new Date(startDate);
                    filteredUsers = filteredUsers.filter(user => user.creation?.toDate() >= start);
                }
                if (endDate) {
                    const end = new Date(endDate);
                    filteredUsers = filteredUsers.filter(user => user.creation?.toDate() <= end);
                }
    
                // Filtro por departamento
                const departmentMatch = selectedDepartment 
                    ? (user) => user.department === selectedDepartment 
                    : () => true;
    
                // Filtro por idc
                const idcMatch = selectCompany 
                    ? (user) => user.idc === selectCompany 
                    : () => true;
    
                // Aplicar ambos filtros
                filteredUsers = filteredUsers.filter(user => departmentMatch(user) && idcMatch(user));
    
                // Filtro por fecha
                const sortedUsers = filteredUsers.sort((a, b) => {
                    let comparison = 0;
                    if (sortType === 'name') {
                        comparison = a.name.localeCompare(b.name);
                    } 
                    return sortDirection === 'desc' ? comparison : -comparison;
                });
    
                // Extraer departamentos únicos
                const departmentsSet = new Set(fetchedInfo.map(user => user.department));
                setDepartments([...departmentsSet]);
    
                if (isMounted) setInfo(sortedUsers);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
            return () => { isMounted = false; }
        }
        fetchInfo();
    }, [startDate, endDate, sortType, sortDirection, selectedDepartment, selectCompany]);


    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        handler();
        return () => {
            handler.cancel();
        };
    }, [searchTerm]);
    
   

    const usersItems = useMemo(() => 
        info ? info.filter(user => 
            user.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.lastname?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            user.email?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        ) : [], [debouncedSearchTerm, info]
    );

    // Calculate the total number of pages
    const numberOfPages = usersItems.length > 0 ? Math.ceil(usersItems.length / itemsPerPage) : 1;

    // Ensure the current page is within a valid range
    useEffect(() => {
        if (currentPage > numberOfPages) {
            setCurrentPage(numberOfPages);
        }
    }, [numberOfPages, currentPage]);

    const displayItems = usersItems.length > 0 
        ? usersItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) 
        : [];


    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info || !Array.isArray(info)) return <p>No user info</p>;
    return (
        <div className="equipo-admin">
            <ListUsersAprove
                info={info}
                handleChange={handleChange}
                sortDirection={sortDirection}
                setSortType = {setSortType}
                setSortDirection={setSortDirection}
                displayItems={displayItems}
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setSelectedDepartment={setSelectedDepartment}
                selectedDepartment={selectedDepartment}
                departments={departments}
                company={company}
                selectCompany={selectCompany}
                setSelectCompany={setSelectCompany}
            />
        </div>
    );
};