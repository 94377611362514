// Paqueteria de efectos
import { useEffect, useState } from 'react';
import { BiX } from 'react-icons/bi';
import { db } from '../../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { FormRegisterCatalogs } from '../../UI/molecules/Form_Register/Form_RegisterCatalogs';



export const Catalogue = ({setIsCatalogue, idCatalog}) => {
    const id = idCatalog;
    const [ userData, setUserData ] = useState(null);
    const [ galleryData, setGalleryData ] = useState([]);
    const [ galleryData1, setGalleryData1 ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ isOpenR, setIsOpenR] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
          if (id) {
            try {
              const userDocRef = doc(db, 'catalogs', id);
              const userDoc = await getDoc(userDocRef);
              if (userDoc.exists()) {
                const data = userDoc.data();
                setUserData(data);
                if (data.gallery && data.gallery.length > 0) {
                  const galleryDocs = await Promise.all(
                    data.gallery.map(async (galleryId) => {
                      const galleryDocRef = doc(db, 'catalogs_product', galleryId);
                      const galleryDoc = await getDoc(galleryDocRef);
                      return galleryDoc.exists ? galleryDoc.data() : null;
                    })
                  );
                  setGalleryData(galleryDocs.filter(doc => doc !== null));
                }
                if (data.gellery1 && data.gellery1.length > 0) {
                    const galleryDocs = await Promise.all(
                      data.gellery1.map(async (galleryId) => {
                        const galleryDocRef = doc(db, 'catalogs_product', galleryId);
                        const galleryDoc = await getDoc(galleryDocRef);
                        return galleryDoc.exists ? galleryDoc.data() : null;
                      })
                    );
                    setGalleryData1(galleryDocs.filter(doc => doc !== null));
                }
              } else {
                console.log('No se encontró el documento!');
              }
            } catch (error) {
                console.log('Error al obtener el documento: ' + error.message);
            } finally {
              setLoading(false);
            }
          }
        };
        fetchUser();
    }, [id]);

    const toggleLogin = () => {
        setIsCatalogue (prevIsLogin=> !prevIsLogin);
    };

    const toggleRe = () => {
        setIsOpenR(prevIsOpenR => !prevIsOpenR);
    }; 

    return (
        <div className="container-login">
            <button onClick={toggleLogin} className='button-x-flotant'>
                <BiX />
            </button>
            <div className="section-log">
                {!isOpenR
                    ?<>
                        {!loading&&
                            <section className="container-gallery-catalog">
                                    {userData.type!=="001"&&
                                        <div className="container-gallery-catalog-title">
                                            <h2 className='gallery-title-catalog' dangerouslySetInnerHTML={{ __html: userData.title }}></h2>
                                            <img className='gallery-icon-catalog'  src={userData.icon} alt={userData.title}/>
                                        </div>
                                    }
                                    {userData.type==="001"&&
                                        <div className='gallery-four-column-scroll'>
                                            <div className="container-gallery-catalog-title  space-title1">
                                                <h2 className='gallery-title-catalog' dangerouslySetInnerHTML={{ __html: userData.title1 }}></h2>
                                                <img className='gallery-icon-catalog'  src={userData.icon} alt={userData.title}/>
                                            </div>
                                            <div className='container-gallery-catalog-description'>
                                                <p  className='gallery-description-catalog'  dangerouslySetInnerHTML={{ __html: userData.description }}></p>
                                            </div>
                                            <div className='gallery-tree-column'>
                                                {galleryData1.map((item, index) => (
                                                    <div key={index} className='container-scroll-column-produc'>
                                                        <div className='gallery-two-column-produc'>
                                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                                <img className='gallery-two-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                                            ))}
                                                        </div>
                                                        <div className='contect-two-column-produc'>
                                                            <p>{item.numb}</p>
                                                            <h4>{item.name}</h4> 
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="container-gallery-catalog-title  space-title">
                                                <h2 className='gallery-title-catalog' dangerouslySetInnerHTML={{ __html: userData.title2 }}></h2>
                                                <img className='gallery-icon-catalog'  src={userData.icon2} alt={userData.title}/>
                                            </div>
                                            <div className='container-gallery-catalog-description'>
                                                <p  className='gallery-description-catalog'  dangerouslySetInnerHTML={{ __html: userData.description1 }}></p>
                                            </div>
                                            <div className='gallery-tree-column'>
                                                {galleryData.map((item, index) => (
                                                    <div key={index} className='container-scroll-column-produc'>
                                                        <div className='gallery-two-column-produc'>
                                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                                <img className='gallery-two-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                                            ))}
                                                        </div>
                                                        <div className='contect-two-column-produc'>
                                                            <p>{item.numb}</p>
                                                            <h4>{item.name}</h4> 
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='container-button-quotemform'>
                                                <button className="button-tipe2"  onClick={toggleRe}>
                                                    Pide tu cotización
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {userData.type==="002"&&
                                        <>
                                            <div className='container-gallery-catalog-description'>
                                                <p  className='gallery-description-catalog'  dangerouslySetInnerHTML={{ __html: userData.description }}></p>
                                            </div>
                                            <div className='gallery-two-column'>
                                                {galleryData.map((item, index) => (
                                                    <div key={index} className='container-scroll-column-produc'>
                                                        <div className='gallery-one-column-produc'>
                                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                                <img className='gallery-one-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                                            ))}
                                                        </div>
                                                        <div className='contect-two-column-produc'>
                                                            <p>{item.numb}</p>
                                                            <h4>{item.name}</h4> 
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='container-button-quotemform'>
                                                <button className="button-tipe2"  onClick={toggleRe}>
                                                    Pide tu cotización
                                                </button>
                                            </div>
                                        </>
                                    }
                                    {userData.type==="003"&&
                                        <div className='gallery-four-column-scroll'>
                                            <div className='container-gallery-catalog-description'>
                                                <p  className='gallery-description-catalog'  dangerouslySetInnerHTML={{ __html: userData.description }}></p>
                                            </div>
                                            <div className='gallery-four-column'>
                                                {galleryData.map((item, index) => (
                                                    <div key={index} className='container-scroll-column-produc'>
                                                        <div className='gallery-four-column-produc'>
                                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                                <img className='gallery-four-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                                            ))}
                                                        </div>
                                                        <div className='contect-two-column-produc'>
                                                            <p>{item.numb}</p>
                                                            <h4>{item.name}</h4>
                                                        </div>
                                                        <div className='description-two-column-produc'>
                                                            <p>{item.content}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="container-gallery-catalog-title  space-title">
                                                <h2 className='gallery-title-catalog' dangerouslySetInnerHTML={{ __html: userData.title2 }}></h2>
                                                <img className='gallery-icon-catalog'  src={userData.icon2} alt={userData.title}/>
                                            </div>
                                            <div className='gallery-four-column1'>
                                                {galleryData1.map((item, index) => (
                                                    <div key={index} className='container-scroll-column-produc'>
                                                        <div className='gallery-four-column-produc'>
                                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                                <img className='gallery-four-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                                            ))}
                                                        </div>
                                                        <div className='contect-two-column-produc'>
                                                            <p>{item.numb}</p>
                                                            <h4>{item.name}</h4> 
                                                        </div>
                                                        <div className='description-two-column-produc'>
                                                            <p>{item.content}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='container-button-quotemform'>
                                                <button className="button-tipe2"  onClick={toggleRe}>
                                                    Pide tu cotización
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {userData.type==="004"&&
                                        <>
                                            <div className='container-gallery-catalog-description'>
                                                <p  className='gallery-description-catalog'  dangerouslySetInnerHTML={{ __html: userData.description }}></p>
                                            </div>
                                            <div className='gallery-five-column'>
                                                {galleryData.map((item, index) => (
                                                    <div key={index} className='container-scroll-column-produc'>
                                                        <div className='gallery-one-column-produc'>
                                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                                <img className='gallery-one-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                                            ))}
                                                        </div>
                                                        <div className='contect-two-column-produc'>
                                                            <p>{item.numb}</p>
                                                            <h4>{item.name}</h4> 
                                                        </div>
                                                        <div className='description-two-column-produc'>
                                                            <p>{item.content}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='container-button-quotemform'>
                                                <button className="button-tipe2"  onClick={toggleRe}>
                                                    Pide tu cotización
                                                </button>
                                            </div>
                                        </>
                                    }
                            </section>
                        }
                    </>
                    :<FormRegisterCatalogs toggleRe={toggleRe}/>
                }
            </div>
        </div>
    );
};