import { BiMessageAltError ,  BiPackage, BiHome , BiRocket , BiLoader , BiMessageAltX} from "react-icons/bi";

export const DeliveryStatusIcon = ({info}) => {
    // Impresion de status de envio o la orden
    let status;
    if ( info === 0)  {
        status = <p className="verification"><BiMessageAltError /></p>;
    } else if ( info === 1 ||info === 2|| info === 15 ||  info === 13 || info === 14 || info === 4) {
        status = <p className="preparation">< BiPackage /></p>;
    } else if ( info === 5 || info === 12 || info === 11) {
        status = <p className="route"><BiRocket /></p>;
    } else if ( info === 6 ) {
        status = <p className="delivered"><BiHome /></p>;
    } else if ( info === 7 || info === 8 ) {
        status = <p className="cancelled"><BiMessageAltX /></p>;
    } else if ( info === "" ) {
        status = <p className="loanding">...</p>;
    } else {
        status = <p className="unassigned"><BiLoader /></p>;
    }
    return (
        <div>
            {status}
        </div>
    );
};