import "./KitStatistics.scss";
import { useEffect, useState } from "react";
import { collection, getDocs} from "firebase/firestore"; 
import { db } from "../../../../firebase/config";
import { PieChart } from '@mui/x-charts/PieChart';
import { FiRotateCcw, FiTruck, FiX, FiZap } from "react-icons/fi";


export  const KitStatistics = ({idk, typecompany, ordenNumb}) => {
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false)
    const [ orden, setOrden] = useState([]);
    const [ users, setUsers] = useState([]);
    const [ combinedOrders, setCombinedOrders ] = useState([]);
    
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'orden');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }))
                    .filter(orden => orden.idk === idk);
                    
                if (isMounted) {
                    setOrden(fetchedInfo);

                    // Procesar order_status_id para contadores
                    const statusCounts = fetchedInfo.reduce((acc, orden) => {
                        const statusId = orden.order_status_id;
                        acc[statusId] = (acc[statusId] || 0) + 1;
                        return acc;
                    }, {});
                    setCombinedOrders(statusCounts);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        const fetchKits = async () => {
            try {
                const q = collection(db, 'kit_application');
                const querySnapshot = await getDocs(q);
                const fetchedKits = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }))
                    .filter(orden => orden.idk === idk);

                if (isMounted) {
                    const combinedUsers = fetchedKits
                        .map(kit => kit.users)
                        .reduce((acc, usersArray) => acc.concat(usersArray), []);
                    setUsers(combinedUsers);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        fetchKits();
        return () => { isMounted = false; }
    }, [idk]);

    const delivery = () => {
        const delivery = [1, 2, 13, 14, 4, 5, 15];
        return delivery.reduce((acc, statusId) => {
            return acc + (combinedOrders[statusId] || 0);
        }, 0);
    };

    const committed = () => {
        const committed = [6];
        return committed.reduce((acc, statusId) => {
            return acc + (combinedOrders[statusId] || 0);
        }, 0);
    };

    const returned = () => {
        const returned = [7];
        return returned.reduce((acc, statusId) => {
            return acc + (combinedOrders[statusId] || 0);
        }, 0);
    };

    const canceled = () => {
        const cancele = [8];
        return cancele.reduce((acc, statusId) => {
            return acc + (combinedOrders[statusId] || 0);
        }, 0);
    };

    const percentage = ((users.length / orden.length)*100)
    const roundedPercentage = Math.round(percentage);
    const remainingPercentage = 100 - roundedPercentage; 

    const dateGrafic = [
        { label: 'Contestados', value: roundedPercentage },
        { label: 'Pendientes', value: remainingPercentage }
    ];


    if (loading) return <div>Loanding ordenes...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <>  
            {orden.length===0
                ?<div className="empty-form">
                    <img className="img-shofi-loanding" alt="Quiz Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2FPJ_To_do_Checklist-PhotoRoom.png-PhotoRoom.png?alt=media&token=af91c841-59f3-475c-ab05-7ad9334ca226&_gl=1*hcsaao*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNDI3LjEuMC4w" />
                    <h2 className="title-shofi-loanding">Los kit fueron asignados, esperemos que comiencen a ser aceptados por tus colaboradores</h2>
                </div>
                :<div className="container-dates-dashboard">
                    {typecompany==="C000001"
                        ?<>
                            <h4 className="subtitle3">Respuesta de usuarios:</h4>
                            <div className='dates-dashboard-kits'>
                                <PieChart
                                    colors={['#1E67E2', '#85D0D5']}
                                    series={[{
                                        paddingAngle: 1,
                                        innerRadius: 70,
                                        outerRadius: 90,
                                        cornerRadius: 19,
                                        data: dateGrafic,
                                    }]}
                                    margin={{ right: 5 }}
                                    width={200}
                                    height={200}
                                    legend={{ hidden: true }}
                                />
                                <h2 className="title  result">{roundedPercentage.toFixed(0)}%</h2>
                            </div>
                        </>
                        :<>
                            <h4 className="subtitle3">Numero de servicios solicitados:</h4>
                            <img className="img-shofi-loanding" alt="Quiz Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2FPJ_To_do_Checklist-PhotoRoom.png-PhotoRoom.png?alt=media&token=af91c841-59f3-475c-ab05-7ad9334ca226&_gl=1*hcsaao*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNDI3LjEuMC4w" />
                            <h5 className="orden-numb">Servicios: {ordenNumb}</h5>
                        </>
                    }
                    <h4 className="subtitle3">Estado de entregas:</h4>
                    <div className="container-dates-delivery">
                        <div className="dates-delivery">
                            <FiTruck className=" icon1"/>
                            <p>En Ruta:<br></br><span>{delivery()}</span></p>
                        </div>
                        <div className="dates-delivery">
                            < FiZap className=" icon2"/>
                            <p>Entregado:<br></br><span>{committed()}</span></p>
                        </div>
                        <div className="dates-delivery">
                            <FiRotateCcw  className=" icon3"/>
                            <p>Devueltos:<br></br><span>{returned()}</span></p>
                        </div>
                        <div className="dates-delivery">
                            <FiX  className=" icon4"/>
                            <p>Cancelados:<br></br><span>{canceled()}</span></p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}