import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useLocation } from "react-router-dom";
import { BiSortDown , BiSortUp } from "react-icons/bi";
import { ItenOrdenAprove } from '../../molecules/ItenOrdenAprove/ItenOrdenAprove';
import { ItenOrdenUpdate } from '../../molecules/ItenOrdenUpdate/ItenOrdenUpdate';

export const ListUsersAprove = ({company, setSelectCompany, selectCompany, selectedDepartment, setSelectedDepartment, departments, setSearchTerm, searchTerm, startDate, endDate, setStartDate, setEndDate,info, setSortType , setSortDirection , handleChange , sortDirection, numberOfPages, displayItems, currentPage}) => {
    const location = useLocation(); 
    
    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        if (new Date(endDate) < new Date(e.target.value)) {
            setEndDate('');
        }
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };
    const validNumberOfPages = numberOfPages > 0 ? numberOfPages : 1;
    const validCurrentPage = currentPage > validNumberOfPages ? validNumberOfPages : currentPage;
    
    return (
        <div className="list-users">
            <div className="contect-list-users">
                <div className="encabezado-users-list">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar usuarios..."
                        value={searchTerm} 
                        onChange={e => setSearchTerm(e.target.value)} 
                    />
                </div>
                    <>
                        {location.pathname === "/order"
                            ?<div className="title-user-list">
                                <p>Avatar</p>
                                <div className="filter">
                                    <button onClick={() => setSortType('nombre')}>Nombre</button>
                                    <button onClick={() => setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))}>
                                        {sortDirection === 'asc' ? <BiSortDown />  : <BiSortUp />}
                                    </button>
                                </div>
                                <p>
                                    Email
                                </p>
                                <div className="filter">
                                    <select 
                                        value={selectedDepartment} 
                                        onChange={e => setSelectedDepartment(e.target.value)}
                                    >
                                        <option value="">Departamento</option>
                                        {departments.map(dept => (
                                            <option key={dept} value={dept}>{dept}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="filter">
                                    Teléfono
                                </div>
                                <div className="filter">
                                    <input 
                                        type="date" 
                                        value={startDate} 
                                        onChange={handleStartDateChange} 
                                    />
                                    <input 
                                        type="date" 
                                        value={endDate} 
                                        onChange={handleEndDateChange} 
                                        min={startDate}
                                    />
                                </div>
                            </div>
                            :<div className="title-user-orden-list">
                                <p>Avatar</p>
                                <div className="filter">
                                    <button onClick={() => setSortType('nombre')}>Nombre</button>
                                    <button onClick={() => setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))}>
                                        {sortDirection === 'asc' ? <BiSortDown />  : <BiSortUp />}
                                    </button>
                                </div>
                                <div className="filter">
                                    <select 
                                        value={selectedDepartment} 
                                        onChange={e => setSelectedDepartment(e.target.value)}
                                    >
                                        <option value="">Departamento</option>
                                        {departments.map(dept => (
                                            <option key={dept} value={dept}>{dept}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='filter'>
                                    <select 
                                        value={selectCompany || ''} 
                                        onChange={e => setSelectCompany(e.target.value)}
                                    >
                                        <option value="">Compañia</option>
                                        {company.map(compa => (
                                            <option key={compa.id} value={compa.id}>{compa.namecompany}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="filter">
                                    Entrega
                                </div>
                                <div className="filter">
                                    <input 
                                        type="date" 
                                        value={startDate} 
                                        onChange={handleStartDateChange} 
                                    />
                                    <input 
                                        type="date" 
                                        value={endDate} 
                                        onChange={handleEndDateChange} 
                                        min={startDate}
                                    />
                                </div>
                                <p>
                                    Status
                                </p>
                            </div>
                        }
                        {displayItems.length!==0
                            ?<>
                                <div className="list">
                                    {location.pathname === "/order"
                                        ?<>
                                            {displayItems.map(users => (
                                                <ItenOrdenAprove key={users.id} {...users} />
                                            ))}
                                        </>
                                        :<>
                                            {displayItems.map(users => (
                                                <ItenOrdenUpdate key={users.id} {...users}/>
                                            ))}
                                        </>
                                    }
                                </div>
                                <div className="pagination-numb">
                                    <Stack spacing={2}>
                                        <Pagination 
                                            count={validNumberOfPages} 
                                            page={validCurrentPage} 
                                            onChange={handleChange} 
                                            size="large" 
                                        />
                                    </Stack>
                                </div>
                            </>
                            :<>
                                <div className="windons-quiz-init">
                                    <div className="quiz-init-img">
                                        <h2 className="title-shofi-loanding">No hay usuarios con estas características...</h2>
                                    </div>
                                </div>
                            </>
                        }
                    </>
            </div>
        </div>
    );
}