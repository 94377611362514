const NameCompany = [
    {
        id:"C000001",
        name:"Admin general"
    },
    {
        id:"C000002",
        name:"Admin central"
    },
    {
        id:"C000003",
        name:"Admin diagnóstico"
    },
]

export default NameCompany;