import "./ItenOrdenUpdate.scss";
import { formateDate } from "../../../../formateDate";
import { useEffect, useState } from "react";
import { db } from "../../../../firebase/config";
import { doc, updateDoc , Timestamp, getDoc, query, collection, where, getDocs, writeBatch} from "firebase/firestore";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { DeliveryStatusIcon } from "../../atoms/DeliveryStatusIcon/DeliveryStatusIcon";
import { FiAlertTriangle,  FiCheck } from "react-icons/fi";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenOrdenUpdate = ({creation, deleveryData, idu, id, idc, date_delivery, address, lastname, department, order_status_id, order_pictures}) => {
    const shortenedName1 = reduceLength((deleveryData.name || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength(deleveryData.lastname ? deleveryData.name + " " + deleveryData.lastname : deleveryData.name, 16);
    const shortenedEmail = reduceLength(department+", "+address+ ", " +lastname || "", 35);
    const [updateDate, setUpdateDate] = useState("");
    const [updateDateAprovec, setUpdateDateAprovec] = useState(false);
    const [newCompanyInfo, setNewCompanyInfo] = useState([]);
    


    const date_update_timestamp = updateDate ? Timestamp.fromDate(new Date(updateDate)) : null;
    const update = date_delivery && 
        typeof date_delivery === 'object' && 
        date_update_timestamp && 
        date_update_timestamp.seconds === date_delivery.seconds && 
        date_update_timestamp.nanoseconds === date_delivery.nanoseconds;

    useEffect(() => {
        if (date_delivery && typeof date_delivery === 'object' && date_delivery.seconds) {
            const date = new Date(date_delivery.seconds * 1000);
            setUpdateDate(date.toISOString().split('T')[0]);
        } else if (date_delivery === "" || date_delivery === undefined || date_delivery === null) {
            setUpdateDate(date_delivery);
        } else {
            console.warn("Unexpected value for date_delivery:", date_delivery);
        }
    }, [date_delivery]);

    useEffect(() => {
        const loadCompanyData = async () => {
            try {
                const docRef = doc(db, 'company', idc);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const companyData = docSnap.data();
                    setNewCompanyInfo(companyData);
                }
            } catch (error) {
                console.error('Error al cargar los datos: ' + error.message);
            }
        };
        loadCompanyData();
    }, [idc]);  


    const handleUpdateDate = (e) => {
        setUpdateDate(e.target.value);
        setUpdateDateAprovec(false)
    };

    const handleUpdate = async () => {
        try {
            const userDocRef = doc(db, 'orden', id);
            // Convierte updateDate a un objeto Date y luego a un Timestamp
            const dateDeliveryTimestamp = updateDate ? Timestamp.fromDate(new Date(updateDate)) : null;
            // Actualiza el documento en la colección 'orden'
            await updateDoc(userDocRef, {
                date_delivery: dateDeliveryTimestamp,
            });
            // Busca y actualiza en la colección 'products-assignment'
            const productsAssignmentRef = collection(db, 'products-assignment');
            const q = query(productsAssignmentRef, where('ido', '==', id));
            const querySnapshot = await getDocs(q);
            const batch = writeBatch(db);
            querySnapshot.forEach((doc) => {
                batch.update(doc.ref, { date_delivery: dateDeliveryTimestamp });
            });
            await batch.commit();
            setUpdateDateAprovec(true);
            toast.success('Información actualizada con éxito');
        } catch (error) {
            toast.error(`Error updating documents: ${error.message}`);
        }
    };
    
    const pintures = order_pictures.length !== 0

    // Fecha de creacion de users
    const fechaCreate= formateDate(creation);
    return (
        <div key={idu} to={`/order/${idu}/${id}`} className="ItenOrdenUpdate">
            <Link className="avatar-ItenOrdenUpdate" to={`/order/update/${id}`}>
                <h2 className="avatar-letter">{shortenedName1}</h2>
            </Link>
            <Link className="name-user-upade" to={`/order/update/${id}`}>
                <p>{shortenedName2}</p>
                <DeliveryStatusIcon   info={order_status_id}/>
                {pintures
                    ?<p className="delivered"><FiCheck /></p>
                    :<p className="verification"><FiAlertTriangle /></p>
                }
            </Link>
            <p className="email">{shortenedEmail}</p>
            <p>{newCompanyInfo.namecompany}</p>
            <div className="update-date">
                <input 
                    type="date" 
                    className="date-ordenUpdate"
                    onChange={handleUpdateDate} 
                    value={updateDate} 
                />
            </div>
            <p className="date">{fechaCreate}</p>
            {!updateDateAprovec
                ?<button  onClick={(!update && updateDate !== "" )? handleUpdate : undefined}  className={(!update && updateDate !== "" )?"update-button":"null-button"}>
                    {(!update && updateDate !== "" )?"Cambiar":"Sin Cambio"}
                </button>
                :<button  className="aprove-button">
                    Actualizado
                </button>
            }
        </div>
    );
}
