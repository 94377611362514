import "./Politcas.scss"
// Secciones componentes de Nosotros
export const TerminosSiigo = () => {
    return ( 
        <div className="main-politics">
            <section className="contect-politycs">
                <h1 className="title">Términos y condiciones del “Beneficio Siigo – SHOFI mobiliario</h1>
                <h2 className="subtitle3">Beneficio:</h2>
                <p>El beneficio Siigo – SHOFI mobiliario es un beneficio (en adelante, el “Beneficio”) 
                    otorgado por SHOFI S.A.S, identificada con 901.071.882-1 (en adelante, “SHOFI”) 
                    o por cualquier otra sociedad del grupo que en su momento determine SHOFI. Con SHOFI lograrás 
                    habilitar los espacios de trabajo en casa de tus colaboradores, en cualquier parte de Colombia.  
                    SHOFI suministra el mobiliario, equipos tecnológicos, periféricos y otros servicios para que  
                    tu empresa se ahorre dolores de cabeza y tu personal sea más feliz y productivo a nivel nacional 
                    en el territorio Colombiano disponible en el sitio web www.goshofi.com (en adelante, el “Sitio Web”), 
                    en los términos descritos en estos Términos y Condiciones. </p>
                <h2 className="subtitle3">El Beneficio que se otorgará a los usuarios de la plataforma Siigo es:</h2>
                <p>
                    30% de descuento por los primeros 3 meses para contratos con duración de 12 meses o más:
                </p>
                <p>
                    <lu>
                        <li>Kit Novo 12 meses: 85.000 COP/mes - Con Siigo solo pagas $59.500/mes.</li>
                        <li>Kit Esencial 12 meses: $100.00 COP/mes - Con Siigo solo pagas $70.000/mes.</li>
                        <li>Kit Pro 12 meses: $300.000 COP/mes - Con Siigo solo pagas $210.000/mes.</li>
                        <p>* Precios sin IVA</p>
                    </lu>
                </p>
                <p>
                    Los usuarios que cumplan con las condiciones descritas en estos Términos y Condiciones se denominan el 
                    “Participante” o los “Participantes” los cuales podrán acceder al beneficio ingresando a la página 
                    <a href="https://goshofi.com/index.php/invitacion-2/" > Aliados Siigo </a> 
                    en la cual diligenciarán su información de contacto 
                    para solicitar un presupuesto o agendar una cita con el área comercial de SHOFI.
                </p>
                <p>
                    La participación en el Beneficio implica el conocimiento y la aceptación total e incondicional de los presentes Términos y Condiciones, así como los Términos y Condiciones de Uso de SHOFI, los cuales se pueden encontrar en la siguiente URL: 
                    <a href="https://www.goshofi.com/tratamiento_de_datos_personales" > Terminos y condiciones</a> 
                </p>
                <p>
                    Cualquier violación a estos Términos y Condiciones o a los procedimientos y/o sistemas establecidos implicará la inmediata exclusión del Participante del Beneficio, sea que ello ocurra en forma previa o posterior a su realización. 
                </p>
                <h2 className="subtitle3">Requisitos del beneficio:</h2>
                <p>
                    Los requisitos para obtener el Beneficio son, además de cumplir con las demás condiciones previstas en los Términos y Condiciones de SHOFI, (i) registrarse por primera vez como usuario del Sitio Web, (ii) ser usuario de la plataforma Siigo, (iii) recibir la comunicación vía correo electrónico, mensaje de WhatsApp o por el aplicativo Siigo en la cual se haya informado que son elegibles para acceder a Beneficio.  
                </p>
                <p>
                    <lu>
                        <li>El Beneficio no es redimible en dinero, no es acumulable con otras promociones y es personal e intransferible..</li>
                        <li>El Beneficio ofrecido no es acumulable con otros beneficios y/o promociones..</li>
                    </lu>
                </p>
                <h2 className="subtitle3">Vigencia del beneficio:</h2>
                <p>El Beneficio será válido para las cuentas que se vinculen desde las 00:00 horas del 1 enero del 2024 y que cumplan con los requisitos previstos en estos Términos y Condiciones.</p>
                <h2 className="subtitle3">Condiciones generales del beneficio:</h2>
                <p>
                    <lu>
                        <li>La participación de los Participantes, implica el pleno conocimiento y aceptación de los Términos y Condiciones de SHOFI, así como los de este Beneficio y los requisitos para participar en el mismo. SHOFI se reserva el derecho de modificar, ampliar y/o aclarar estos Términos y Condiciones, así como de interrumpir o suspender el Beneficio en cualquier momento durante el Plazo.</li>
                        <li>Se aceptan los presentes Términos y Condiciones con el hecho de participar en el Beneficio. Con dicha aceptación el usuario autoriza a SHOFI, en calidad de Responsable, a recopilar, almacenar, procesar, actualizar, utilizar y transmitir su información personal para las finalidades de ejecutar debidamente la actividad y hacer efectivo el Beneficio, así como las demás que se encuentran en sus políticas de tratamiento de datos personales, salvo manifestación escrita en sentido opuesto. Las políticas de tratamiento de datos personales de SHOFI pueden ser consultadas en: 
                            <a href="https://www.goshofi.com/tratamiento_de_datos_personales">https://www.goshofi.com/tratamiento_de_datos_personales</a>
                        </li>
                        <li>SHOFI se reserva el derecho de cancelar el Beneficio para el Participante, en caso de (i) utilizar la marca, nombre, logo y/o cualquier otro elemento que sea de titularidad de SHOFI, sin haber obtenido el previo consentimiento de SHOFI, (ii) crear páginas web que intenten, pretendan o puedan llegar a confundirse o hacerse pasar por alguna de las páginas web oficiales de SHOFI. Adicionalmente, SHOFI se reserva el derecho de: (i) </li>
                        <li>El Beneficio ofrecido es de carácter personal e intransferible, válido únicamente para los usuarios registrados en la plataforma Siigo. El Beneficio ofrecido no es acumulable con otros beneficios y/o promociones.</li>
                        <li>Los servicios promocionados por Siigo son 100% virtuales o remotos.</li>
                        <li>Quienes accedan a la promoción u oferta descrita deberán aceptar las condiciones de uso del servicio promocionado, publicadas en 
                            <a href="https://www.goshofi.com/tratamiento_de_datos_personales ">https://www.goshofi.com/tratamiento_de_datos_personales </a>
                            y la política de privacidad de Siigo, publicada en <a href=" https://www.siigo.com/politicas-de-uso/."> https://www.siigo.com/politicas-de-uso/.</a>
                        </li>
                        <li>Siigo es un software contable que facilita el acceso de pequeñas y medianas empresas a productos y servicios ofrecidos por plataformas de pago aliadas.</li>
                        <li>Para más información sobre funcionalidades, requisitos de vinculación, tiempos de aprobación y demás conceptos aplicables, puede recurrir a 
                            <a href="https://www.goshofi.com/tratamiento_de_datos_personales">https://www.goshofi.com/tratamiento_de_datos_personales</a>
                        </li>
                        <li>Siigo no garantiza la vinculación a SHOFI y no es responsable de ningún aspecto relacionado con la activación del servicio al cliente en la plataforma SHOFI o en el momento de la entrega de los elementos en renta o alquiler </li>
                    </lu>
                </p>
                <h2 className="subtitle3">Territorio y/o país al cual aplica:</h2>
                <p>Aplica únicamente para Colombia.</p>
                <h2 className="subtitle3">Solicitudes, reclamaciones o quejas:</h2>
                <p>Si existen dudas, reclamaciones o quejas acerca de esta actividad, los clientes activos podrán consultar más a través de los canales de atención telefónicos, señalados en la página web, o a través del correo electrónico siigoservicios@siigo.com .</p>
            </section>
        </div>
    );
}
