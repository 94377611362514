import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiPaperclip, BiX } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { toast } from "react-toastify";
import { LuSave } from "react-icons/lu";
import { DeliveryStatusOrden } from "../../../UI/atoms/DeliveryStatusOrden/DeliveryStatusOrden";
import { formateDate } from "../../../../formateDate";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FiArrowRightCircle } from "react-icons/fi";

const formatDateWithTimezone = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    
    const timezoneOffset = -date.getTimezoneOffset(); // Diferencia en minutos con la zona horaria UTC
    const sign = timezoneOffset >= 0 ? '+' : '-';
    const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
    const timezoneMinutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${sign}${timezoneHours}:${timezoneMinutes}`;
};


export const UpdateOrdenImagen = () => {
    const { id } = useParams();
    const navigate = useNavigate(); 
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ tempOrderPictures, setTempOrderPictures] = useState([]); 
    const [ formData, setFormData ] = useState({
        name: '',
        lastname:'',
        email: '',
        phone:'',
        cc:'',
        country:'',
        department:'',
        city:'',
        address:'',
        lastaddress:'',
        idc:'',
        idu:'',
        order_pictures: [],
    });

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!id) throw new Error("Id Foil is undefined");
                const docRef = doc(db, 'orden', id);
                const docData = await getDoc(docRef);
                if (docData.exists()) {
                    const data = docData.data(); 
                    setFormData(formData => ({
                        ...formData,
                        ...data,
                        ...data.deleveryData 
                    }));
                    setTempOrderPictures(data.order_pictures || []); // Copia inicial de las imágenes
                } else {
                    throw new Error("El documento no existe");
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => {isMounted = false;}
    }, [id, setLoading]);

    // Eliminar imagen temporalmente
    const deleteImage = (index, e) => {
        e.preventDefault(); // Prevenir el recargado de la página
        const updatedPictures = tempOrderPictures.filter((_, i) => i !== index);
        setTempOrderPictures(updatedPictures); // Actualizar las imágenes temporales sin recargar
    };

    // Subir nueva imagen temporalmente (sin actualizar la base de datos)
    const handleUpload = async (e) => {
        const files = e.target.files; // Obtener todos los archivos seleccionados
        if (!files.length) return;

        const newPictures = []; // Para almacenar las nuevas imágenes con sus URLs

        for (const file of files) {
            const storage = getStorage();
            const storageRef = ref(storage, `order_pictures/${file.name}`);
            
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);

            // Generar la fecha actual con el formato necesario
            const currentDate = new Date();
            const formattedDate = formatDateWithTimezone(currentDate);

            const newPicture = {
                file: downloadURL,
                created_at: formattedDate,
            };

            newPictures.push(newPicture); // Agregar la imagen a la lista de nuevas imágenes
        }

        // Actualizar el estado con las imágenes nuevas
        setTempOrderPictures([...tempOrderPictures, ...newPictures]);
    };

    
    const goBack = () => {
        navigate(-1); // Mueve el historial hacia atrás en una entrada
    };

    // Guardar los cambios (actualizar la base de datos)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const docRef = doc(db, 'orden', id);
            await updateDoc(docRef, { order_pictures: tempOrderPictures }); // Actualizar las imágenes en Firebase
            toast.success('Imágenes actualizadas con éxito');
            navigate(-1); // Navegar hacia atrás después de guardar
        } catch (error) {
            setLoading(false);
            toast.error("Error al guardar cambios: " + error.message);
        }
    };

    // Guardar los cambios (actualizar danto status)
    const handleStatus = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const docRef = doc(db, 'orden', id);
            await updateDoc(docRef, { order_status_id: 6 }); // Actualizar las imágenes en Firebase
            const docUser = doc(db, 'users', formData.idu);
            await updateDoc(docUser, { order_status_id: 6 });
            toast.success('Orden a entregado');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Error al guardar cambios: " + error.message);
        }
    };

    // Fecha de creacion de users
    const fechaCreate= formateDate(formData.creation);
    const fechaDateDelivery= formateDate(formData.date_delivery);
    const fechaDateCancel= formateDate(formData.date_cancel);

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="windons-equipo-admin">
            <div className="encabezado-list">
                <button className='button-new' onClick={goBack}>
                    <BiChevronLeft /> Volver
                </button>
                <h2 className="title">Editor orden</h2>
            </div>
            <form  className='scroll-equip-admin'>
                <div className="form-contact-e">
                    <section className='column-img'>
                        <div className='section-img'>
                            <div className='input-img'>
                                <img
                                    className='user'
                                    alt='user'
                                    src='https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'
                                />
                            </div>
                        </div>
                    </section>
                    <section className='section-users'>
                        <div className='column-form2'>
                            <div className='input-date'>
                                <label>Nombre:</label>
                                <input type="text" name="nombre" value={formData.name} readOnly/>
                            </div>
                            <div className='input-date'>
                                <label>Apellido:</label>
                                <input type="text" name="apellido" value={formData.lastname} readOnly/>
                            </div>
                            <div className='input-date'>
                                <label>Email:</label>
                                <input type="email" name="email" value={formData.email} required readOnly />
                            </div>
                            <div className='input-date'>
                                <label>Teléfono:</label>
                                <input type="tel" name="telefono" value={formData.phone}  required readOnly />
                            </div>
                            <div className='input-date'>
                                <label>Cedula CC / PEP:</label>
                                <input type="tel" name="cc" value={formData.cc} required readOnly />
                            </div>
                            <div className='input-date'>
                                <label>Pais:</label>
                                <input type="text" name="pais" value={formData.country} readOnly/>
                            </div>
                            <div className='input-date'>
                                <label>Departamento:</label>
                                <input type="text" name="departamento" value={formData.department} readOnly/>
                            </div>
                            <div className='input-date'>
                                <label>Ciudad:</label>
                                <input type="text" name="departamento" value={formData.city} readOnly/>
                            </div>
                            <div className='input-date'>
                                <label>Dirección:</label>
                                <input type="text" name="departamento" value={formData.address} readOnly/>
                            </div>
                            <div className='input-date'>
                                <label>Detalles:</label>
                                <input type="text" name="departamento" value={formData.lastaddress} readOnly/>
                            </div>
                        </div>
                        <div className='column-form2'>
                            <button  className='button-new2' onClick={ handleStatus }>
                                <FiArrowRightCircle />Entregado
                            </button>
                            <div></div><div></div>
                            <button  className='button-new' onClick={ handleSubmit }>
                                <LuSave />Guardar Cambios
                            </button>
                        </div>
                    </section>
                </div>
                <div className='container-set-users'>
                    <div>
                        <div className="iten-orden-user">
                            <section className="detail-orden-date">
                                <article className="orden-date">
                                    <h4 className="subtitle3" >ID orden: {id}</h4>
                                    {formData.order_siigo_id&&<p>Orden ID:{formData.order_siigo_id}</p>}
                                    <p>{formData.order_velocity}</p>
                                </article>
                                <article className="orden-description">
                                    <DeliveryStatusOrden order_status_id={formData.order_status_id} />
                                    <p className="date-orden">Fecha creación: {fechaCreate}</p>
                                    {formData.order_status_id === 6 && 
                                        <>
                                            <p className="date-sale">Fecha de entrega:{fechaDateDelivery}</p>
                                        </>
                                    }
                                    {(formData.order_status_id === 7 || formData.order_status_id === 8 )&& <p className="date-cancel">Fecha cancelado: {fechaDateCancel}</p>}
                                </article>
                            </section>
                            <section className="detail-orden-products">
                                <div className="container-list-product">
                                    <h2 className="subtitle3">Lista De Productos:</h2>
                                    <div className="container-iten-produc">
                                        <p>Img</p>
                                        <p className="iten-produc-name">Nombre</p>
                                        <p className="iten-produc-sku">SKU</p>
                                    </div>
                                    {formData.order_product.map((item, index) => (
                                        <div key={index + item.product_id} className="container-iten-produc">
                                            <img alt={item.name} src={item.picture_url} />
                                            <p className="iten-produc-name">{item.name}</p>
                                            <p className="iten-produc-sku">{item.sku}</p>
                                        </div>
                                    ))}
                                </div>
                                {tempOrderPictures && (
                                    <div className="container-orden-pintures">
                                        <h2 className="subtitle3">Firma & Registro Fotográfico:</h2>
                                        <div className="orden-pintures">
                                            {tempOrderPictures.map((item, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={item.file} alt={item.file} />
                                                    <button className="delete-img" onClick={(e) => deleteImage(index, e)}>
                                                        <BiX/>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='input-load-imagen'>
                                            <label htmlFor="file-upload" className="custom-file-upload">
                                                <BiPaperclip />Imágenes
                                            </label>
                                            <input id="file-upload" type="file" accept=".jpg, .png" onChange={handleUpload} multiple />
                                        </div>
                                    </div>
                                )}
                            </section>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
