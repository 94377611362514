import "./SectionSupport.scss"
import { useEffect, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, updateDoc, doc, arrayUnion, query, where, getDocs, getDoc } from "firebase/firestore";
import { db , storage } from "../../../../firebase/config";
import { BiPaperPlane, BiPaperclip, BiX } from "react-icons/bi";
import { toast } from "react-toastify";

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendSupportInfo"; 

const getUserDetailsFromUserIds = async (userIds) => {
    const userDetails = [];
    for (const userId of userIds) {
        const userRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();
            userDetails.push({
                email: userData.email,
                name: userData.nombre,
                lastName: userData.apellido,
            });
        }
    }
    return userDetails;
};

async function sendSupport(data) {
    try {
        const payload = {
            ...data,
        };
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        if (response.ok) {
            toast.success("Ticket enviado con éxito");
        } else {
            const errorBody = await response.text();
            toast.error(`Error de envío de ticket: ${response.statusText}`);
            toast.error(`Detalle del error: ${errorBody}`);
        }
    } catch (error) {
        console.error("Error en Ticket:", error);
        toast.error(`Error de envío de email: ${error.message}`);
    }
}

export const SectionSupport = ({ id, idc, compañia, email, rol , setLoading}) => {
    const [files, setFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [error, setError] = useState(null);
    const [commerce, setCommerce]= useState([]);

    useEffect(() => {
        const loadBlogData = async () => {
            try {
                if (!idc) throw new Error("idc es indefinido");
                const docRef = doc(db, "company", idc);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setCommerce(docSnap.data());
                } else {
                    toast.error("No such document!");
                }
            } catch (error) {
                toast.error("Failed to fetch data: " + error.message);
            }
        };
        loadBlogData();
    }, [idc]);

    const functions_team  =[
        {
            value:"Avería de equipos",
            id_team:[
                "yK3rZNV80XSNWhIxOH71m4bA6lm2",
                "QPbqy71UXwOPNscDzfrZeXfCQAx1",
                "0YbxmJDyabQIsF2FlVCjU8Mod7q1",
                "mC6N844tFtNRh0yuT9KVbNVSqgp2",
                commerce.id_commercial,
            ],
        },
        {
            value:"Contabilidad & Facturacion",
            id_team:[
                "yK3rZNV80XSNWhIxOH71m4bA6lm2",
                "mC6N844tFtNRh0yuT9KVbNVSqgp2",
                commerce.id_commercial,
            ],
        },
        {
            value:"Falla en plataforma",
            id_team:[
                "QPbqy71UXwOPNscDzfrZeXfCQAx1",
                "mC6N844tFtNRh0yuT9KVbNVSqgp2",
                commerce.id_commercial,
            ],
        },
        {
            value:"Contactar con Comercial",
            id_team:[
                commerce.id_commercial,
            ],
        },
    ]

    useEffect(() => {
        const fetchGroup = async () => {
            try {
                if (!idc) throw new Error("idc es indefinido");
                    const kitsCollectionRef = collection(db, 'users');
                    const company = query(kitsCollectionRef, where('idc', '==', idc));
                    const q = query(company, where('rol', 'in', ["AA-001", "AA-002", "AA-003"]));
                    const querySnapshot = await getDocs(q);
                    const fetchedIds = querySnapshot.docs.map(doc => doc.id);
                setSelectedGroup(fetchedIds);
            } catch (err) {
                setError(err);
            }
        };
        fetchGroup();
    }, [idc]); 

    const [formData, setFormData] = useState({
        issue: "",
        event: "",
        description: "",
    });

    const filteredTeams = functions_team
        .filter(team => team.value === formData.issue)
        .map(team => (team.id_team));

    const sendAdminFiltered = selectedGroup.filter(emailId => emailId !== id);
    const sendEmailAdmin = [...filteredTeams.flat(), ...sendAdminFiltered.flat()];

    let sendEmail = (rol === "AA-001" || rol === "AA-002" || rol === "AA-003") ? sendEmailAdmin : sendAdminFiltered;
    let asignation = (rol === "AA-001" || rol === "AA-002" || rol === "AA-003") ? id : "";
    let scalability_status = (rol === "AA-001" || rol === "AA-002" || rol === "AA-003")? true : false;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
    };
    
    
    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, fileIndex) => fileIndex !== index);
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => urlIndex !== index);
        setFiles(newFiles);
        setPreviewUrls(newPreviewUrls);
    };        

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const fileUrls = await Promise.all(files.map(async (file) => {
            const fileRef = ref(storage, `support/${file.name}`);
            await uploadBytes(fileRef, file);
            return getDownloadURL(fileRef);
        }));
        try {
            const creation_date = new Date();
            const ticketsCollectionRef = collection(db, "tickets");
            const docRef = await addDoc(ticketsCollectionRef, {
                ...formData,
                idu: id,
                company:compañia,
                scalability: scalability_status,
                idc,
                email,
                assigned_id:asignation,
                super_admin_id:"",
                last_answer:id,
                status:"send",
                creation_date,
                date:creation_date,
                answers_chat: [{
                    message: formData.description, 
                    date:creation_date, 
                    idu:id , 
                    files: fileUrls, 
                    email: email,
                }],
            });
            const newDocumentId = docRef.id;
            // Actualiza los documentos en la colección 'alert' para cada ID en selectedGroup
            const notificationPayload = {
                date: new Date(), 
                affair: formData.event,
                functions: "soporte",
                message: formData.description ,
                route: `/soporte/${newDocumentId}`,
                status: true,
            };
            // Aquí actualizas el documento en la colección 'alert'
            for (const userId of sendEmail) {
                const alertDocRef = doc(db, 'alert', userId);
                await updateDoc(alertDocRef, {
                    notification: arrayUnion(notificationPayload)
                });
            }
            const userDetails = await getUserDetailsFromUserIds(sendEmail);
            const payload = {
                event: formData.issue,
                users: userDetails,
                affair: formData.event,
                comment: formData.description,
                id:newDocumentId,
                timestamp: new Date()
            };
            await sendSupport(payload);
            // Restablecer el formulario y los archivos
            setFormData({
                issue: "",
                event: "",
                description: "",
            });
            setFiles([]);
            setPreviewUrls([]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="section-support">
            <h2>Crea tu ticket de soporte</h2>
            <form className="form" onSubmit={handleSubmit}>
                <div className="form-scroll">
                    <div className='input-date'>
                        <label>
                            Si presentas algún inconveniente con tu equipo, elementos o fallas en el sistema, no dudes en ponerte en contacto con nosotros. 
                            Para ayudarnos a entender mejor tu situación, te recomendamos adjuntar una captura de pantalla o imagen de referencia que ilustre la novedad que estás experimentando. ¡Estamos aquí para ayudarte!
                        </label>
                    </div>
                    
                    <div className='input-date'>
                        <label>Problema:</label>
                        <select name="issue" value={formData.issue} onChange={handleChange} required>
                            <option value="">Selecciona un problema</option>
                            <option value="Avería de equipos">Avería de equipos</option>
                            {(rol === "AA-001" || rol === "AA-002" || rol === "AA-003") && <option value="Contabilidad & Facturacion">Contabilidad y facturación</option>}
                            <option value="Falla en plataforma">Falla en plataforma</option>
                            {(rol === "AA-001" || rol === "AA-002" || rol === "AA-003") && <option value="Contactar con Comercial">Contactar con área comercia</option>}
                        </select>
                    </div>
                    <div className='input-date'>
                        <label>Suceso:</label>
                        <input name="event" value={formData.event} onChange={handleChange} required />
                    </div>
                    <div className='input-date'>
                        <label>Mensaje</label>
                        <textarea name="description" value={formData.description} onChange={handleChange} required />
                    </div>
                    <div className='input-load'>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <BiPaperclip />Imágenes
                        </label>
                        <input id="file-upload" type="file" accept=".jpg, .png" onChange={handleFileChange} multiple />
                    </div>
                    {previewUrls&&
                        <div className="container-input-img">
                            {previewUrls.map((url, index) => (
                                <div key={index} className="input-img">
                                    <img src={url} alt={`Previsualización ${index}`} style={{ width: 'auto', height: '100px' }} />
                                    <button className="delete-img" onClick={() => handleRemoveFile(index)}>
                                        <BiX/>
                                    </button>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="cotainer-button-mens">
                    <button className='button-new' type="submit"><BiPaperPlane />Enviar ticket</button>
                </div>
            </form>
        </div>
    );
}
