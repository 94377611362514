import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../../firebase/config";

export const PlSection3 = ({setLoading})  => {
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'B2Lvge89zqsfGbGOB5GU');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);

    if (error) return <p>Error: {error.message}</p>;
    return (
        <section className="section-contect">
            <div className="section-1Pl-contect-container">
                <div className="section-1Pl-img">
                    <img alt={info.title} src={info.img} className="img-1Pl"/>
                </div>
                <div className="section-1Pl-title">
                    <div className="section-1Pl-icon">
                        <img alt={info.title} src={info.icon} className="icon-1Pl"/>
                    </div>
                    <section className="section-1Pl-title-contect">
                        <h1 className="title-page-1pl" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                        <p className="subtitle-page-1pl" dangerouslySetInnerHTML={{ __html: info.contect }}></p>
                    </section>
                </div>
            </div>
        </section>
    );
}
