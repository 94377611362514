import { Link } from "react-router-dom";
import { BiUser, BiUserPlus } from "react-icons/bi";


function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenUsersOpen = ({name, lastname , email, answer , date , img , id , ider, setIsEditorOpen, setSelectedId}) => {
    const shortenedName1 = reduceLength((name || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength(lastname ? name + " " + lastname : name, 16);
    const shortenedEmail = reduceLength(email|| "", 25);
    
   
    // Fecha de creacion de users
    let fechaFormateada = "";
    if(date) {
        try {
            const fecha = date.toDate(); 
            fechaFormateada = fecha.toLocaleDateString('es-ES'); 
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }
    // Icono de status
    let rolElement;
    if ( answer ) {
        rolElement = <p className="admin"><BiUserPlus />Terminado</p>;
    } else {
        rolElement = <p className="usuario"><BiUser />Pendiente </p>;
    };

    const goOpen = (id) => {
        setSelectedId(id);  // Establecer el id en el estado
        setIsEditorOpen(true);  // Abrir el modal
    };
    
    return (
        <>{(!answer )
            ?<button key={id} className="item-user"  onClick={() => goOpen(id)}> 
                <div className="avatar">
                    {img ? (
                        <div className="avatar-img"> 
                            <img alt="avatar" src={img}/>
                        </div>
                    ) : (
                        <h2 className="avatar-letter">{shortenedName1}</h2>
                    )}
                </div>
                <p className="name-user">{shortenedName2}</p>
                <p className="email">{shortenedEmail}</p>
                <div className="rol">
                    {rolElement}
                </div>
                <p className="phone"></p>
                <p className="date">{fechaFormateada}</p>
            </button>
            :<Link key={id} to={`/open/invitations/${ider}`} className="item-user">
                <div className="avatar">
                    {img ? (
                        <div className="avatar-img"> 
                        <img alt="avatar" src={img}/>
                        </div>
                    ) : (
                        <h2 className="avatar-letter">{shortenedName1}</h2>
                    )}
                </div>
                <p className="name-user">{shortenedName2}</p>
                <p className="email">{shortenedEmail}</p>
                <div className="rol">
                    {rolElement}
                </div>
                <p className="phone"></p>
                <p className="date">{fechaFormateada}</p>
            </Link>
        }</>
    );
}
