import axios from 'axios';
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, Timestamp, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate  } from 'react-router-dom';
import { toast } from 'react-toastify';
// Componente desarrollados
import { AuthContext } from '../../../../context/AuthContext';
import { db } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { BiCabinet, BiChevronLeft, BiPlus, BiSave, BiX } from "react-icons/bi";

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendSales"; 

const getUserDetailsFromUserIds = async (userIds) => {
  const userDetails = [];
  for (const userId of userIds) {
    const userRef = doc(db, 'users', userId);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      if (userData.email) {
        userDetails.push({ email: userData.email });
      } else {
        console.warn(`User ${userId} has no email.`);
      }
    }
  }
  return userDetails;
};


async function sendSupport(data) {
    try {
        const payload = {
            ...data,
        };
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        if (response.ok) {
            toast.success("Ticket enviado con éxito");
        } else {
            const errorBody = await response.text();
            toast.error(`Error de envío de ticket: ${response.statusText}`);
            toast.error(`Detalle del error: ${errorBody}`);
        }
    } catch (error) {
        console.error("Error en Ticket:", error);
        toast.error(`Error de envío de email: ${error.message}`);
    }
}

export const ContestadorAsignaciones = ({ id , idc , business_id, code_orden }) => {
    const { id: idKits} = useParams();
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [kits, setKits] = useState([])
    const [name, setName] = useState([])
    const [info, setInfo] = useState([])
    const [detail, setDetail] = useState([]) 
    const [company, setCompany] = useState([]) 
    const [idProduct, setIdProduct] = useState(null)
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [open, setOpen] = useState(false);
    const [opendetail, setOpenDetail] = useState(false);
    const [searchTermG,  setSearchTermG] = useState('');
    const [searchTerm,  setSearchTerm] = useState('');
    // Captura de datos para orden
    const [customer, setCustomer] = useState({});
    const [shipping, setShipping] = useState({});

    const handleDetail = (idProduct) => {
        setOpen(!open);
        setIdProduct(idProduct);
    };

    const handleDetailClose = () => {
        setOpen(!open);
        setIdProduct(null);
        setOpenDetail(false);
    }; 

    const handleNoDetail = (idProduct) => {
        setOpen(!open);
        setOpenDetail(!opendetail);
        setIdProduct(idProduct);
    }; 


    useEffect(() => {
        const fetchKits = async () => {
            try {
                const docRef = doc(db, "produc_kits", idKits);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const kitData = docSnap.data();
                    setKits(kitData.kits);
                    setName(kitData.name_kits);
                } else {
                    toast.error("No se encontro información del Kit");
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchKits ();
    }, [idKits]);

    useEffect(() => {
        let isMounted = true;
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "produc"));
                if (isMounted) {
                    const allProducts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    const filteredProducts = allProducts.filter(product => kits.includes(product.id));
                    setProducts(filteredProducts); // Solo establece los productos que están en los kits
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchProducts();
        return () => { isMounted = false; };
    }, [kits]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const docRef = doc(db, "users", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setCustomer({
                        document_type_id: userData.typeId,
                        full_name: userData.nombre+" "+userData.apellido,
                        mobile_phone_number: userData.telefono,
                        email: userData.email,
                        dni: userData.cc,
                    });
                    setShipping({
                        address: userData.direccion_1,
                        address_line: userData.direccion_2,
                        dane_code: '1010',
                        city: userData.ciudad,
                        state: userData.departamento,
                        country:'CO',
                    });
                    setInfo(userData);
                } else {
                    toast.error("No se encontró información del usuario");
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [id]);

    useEffect(() => {
        const fetchDetail = async () => {
            if (!idProduct) return;
            try {
                const docRef = doc(db, "produc", idProduct);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setDetail(docSnap.data());
                } else {
                    toast.error("No se encontró información del producto");
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchDetail();
    }, [idProduct]);

    const handleAdd = (productId) => {
        const product = products.find(product => product.id === productId);
        if (product) {
            setSelectedProducts(prevSelected => [...prevSelected, product.id]); // Añadir el producto seleccionado a la lista del kit
        }
    };

    const handleAdds = (productId) => {
        const product = products.find(product => product.id === productId);
        if (product) {
            setSelectedProducts(prevSelected => [...prevSelected, product.id]); // Añadir el producto seleccionado a la lista del kit
            setOpen(!open);
        }
    };

    const handleDelete = (productId) => {
        setSelectedProducts(prevSelected => prevSelected.filter(id => id !== productId)); // Remover el producto seleccionado de la lista del kit
    };

    const selectAll = () => {
        const allProductIds = products.map(product => product.id);
        setSelectedProducts(allProductIds);
    };
    // Datos para crear productos en Producto Asignados
    let datesProducts = selectedProducts.map(productId=>{
        const product = products.find(p => p.id === productId);
        return {
            business_id:business_id,
            category:product.categoryId,
            date_create: new Date(),
            idc: idc,
            idp: product.id,
            idu: id,
            img: product.img,
            name: product.name,
            serial: product.SKU+"-"+code_orden,
            sku: product.SKU+"-"+code_orden,
            tipe:"T-001",
            // Estos campos se actualizarán después
            ido: "", // ID de la orden en Firebase
            ovelocity: "" 
        };
    })
    // Datos de creador de productos
    let createProducts = selectedProducts.map(productId => {
        const product = products.find(p => p.id === productId);
        return {
            name: product.name, 
            sku: product.SKU+"-"+code_orden, //<<< CREAR ID UNICO ESTE DEBE SER LA SUMA DEL product.SKU MAS UN CODIGO DE LETRAS Y NUMEROS NO MAXIMO DE 12 CARACTERES
            external_id: product.id,
            picture_url: product.img,
            price: product.price,
            tax:0,
            cost: product.conste,
            weight: 0,
            large: 0,
            width: 0,
            height: 0,
            warehouses:[138,110],
            business_id:business_id,
            bundle:[],
        };
    });
    // Datos de productos de orden
    let ordenProducts = selectedProducts.map(productId => {
        const product = products.find(p => p.id === productId);
        return {
            price: product.price,
            sku: product.SKU+"-"+code_orden,  //<<< CREAR ID UNICO QUE DEBE SER EL MISMO QUE EL CREDOR DE PRODUCTOS
            quantity:1,
            tax:0,
            discount:0,
            is_custom_kit: false,
        };
    });

    let ordenProductsinter = selectedProducts.map(productId => {
        const product = products.find(p => p.id === productId);
        return {
            picture_url: product.img,
            name: product.name, 
            sku: product.SKU+"-"+code_orden,  //<<< CREAR ID UNICO QUE DEBE SER EL MISMO QUE EL CREDOR DE PRODUCTOS
            quantity:1,
        };
    });


    const filterAsign= products.filter(product => (
        product.name.toLowerCase().includes(searchTerm.toLowerCase())||
        product.category.toLowerCase().includes(searchTerm.toLowerCase())
    ));
   

    let totalSum = ordenProducts.reduce((sum, product) => sum + product.price, 0);

    // Obtener la fecha de entrega de pedido
    const firebaseTimestamp = Timestamp.now();
    const currentDate = firebaseTimestamp.toDate();
    const newDate = new Date(currentDate.getTime() + (5 * 24 * 60 * 60 * 1000));
    const dateISO = newDate.toISOString();

    useEffect(() => {
        const fetchDetail = async () => {
            if (!idc) return;
            try {
                const docRef = doc(db, "company", idc);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setCompany(docSnap.data());
                } else {
                    toast.error("No se encontró información de compañia");
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchDetail();
    }, [idc]);

    // Perfiles
    const functions_team ={
        value:"Nuevo Usuario",
        id_team:[
            "yK3rZNV80XSNWhIxOH71m4bA6lm2",
            "QPbqy71UXwOPNscDzfrZeXfCQAx1",
            "0YbxmJDyabQIsF2FlVCjU8Mod7q1",
            "QPbqy71UXwOPNscDzfrZeXfCQAx1",
            "mC6N844tFtNRh0yuT9KVbNVSqgp2",
            company.id_commercial,
        ],
    }

    const handleSave = async () => {
        setLoading(true);
        if (selectedProducts.length === 0) {
            toast.error("No haz seleccionado ningun producto");
            setLoading(false);
            return;
        }
        if (info.length === 0) {
            toast.error("Error de información de entrega");
            setLoading(false);
            return;
        }
        let allProductsCreated = true;
        for (const productData of createProducts) {
            try {
                const response = await axios.post('https://f.velocity-x.co/api/v1/products', productData, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                console.log('Producto creado:', response.data);
            } catch (error) {
                console.log("Error crear producto:", error.response);
                allProductsCreated = false;
                break;
            }
        }
        if (!allProductsCreated) {
            toast.error("Error al crear uno o más productos");
            setLoading(false);
            return; // Detener la ejecución si no se crearon todos los productos
        }
        try {
            // Creacion de la orden en registro firebase
            const docRef = await addDoc(collection(db, 'orden'), {
                business_id: business_id,
                creation: Timestamp.now(),
                date_delete:"",  //
                date_delivery:"",
                deleveryData: {
                    name: info.nombre,
                    lastname: info.apellido,
                    country: info.pais,
                    department:info.departamento,
                    address:info.direccion_1,
                    lastaddress:info.direccion_2,
                    lat:info.latitud,
                    log:info.longitud,
                    phone:info.telefono,
                    email:info.email,
                    cc:info.cc,
                },
                idc:idc,
                idk:idKits,
                idu:id,
                order_acceptance:true,  //
                order_pictures:[], //
                order_product:ordenProductsinter, 
                order_siigo_id:"", //
                order_status_id:1, 
                order_velocity:"",
                products: selectedProducts,
                re_asign:false, //
                status:true, //
                total:totalSum, //
                idkName:name,  
            });
            //Datos de montaje de la orden en SAAS Velocity
            const orderData = {
                country_id :1,
                payment_method:1,
                integration_type_id:5,
                cod_total: 0,
                total:totalSum,
                order_type_id:4,
                external_order_id:docRef.id,
                order_number:docRef.id,
                warehouse_id: 138,
                total_shipment: 0,
                business_id:business_id,
                delivery_date:dateISO,
                customer: customer,
                shipping_information:shipping, 
                products:ordenProducts,
            };
            //Llamado a la api guardado de orden en SAAS Velocity
            try {
                const response = await axios.post('https://f.velocity-x.co/api/v1/orders', orderData, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                const orderId = response.data.id;
                if (orderId) {
                    // Agregar el ID del usuario al nuevo grupo
                    const upadeData = doc(db, 'orden', docRef.id);
                    await updateDoc(upadeData , {
                        order_velocity:orderId,
                    });
                    // Crear productos en la colección `products-assignment` con los IDs actualizados
                    for (const product of datesProducts) {
                        await addDoc(collection(db, 'products-assignment'), {
                            ...product,
                            ido: docRef.id,
                            ovelocity: orderId,
                            status:true
                        });
                    }
                    // Agregar el ID del usuario al nuevo grupo
                    const upadeDataUser = doc(db, 'users', id);
                    await updateDoc(upadeDataUser , {
                        order_status_id:0,
                        ordens: arrayUnion({
                            creation: Timestamp.now(),
                            id:docRef.id,
                            order_velocity:orderId,
                            order_status_id:0,
                        }),
                    });
                }
                toast.success(`Orden creada con éxito. ID de orden: ${orderId}`);
            } catch (error) {
                toast.error("Error en la solicitud:", error.response);
            }
            // Pasar los IDs directamente a getUserDetailsFromUserIds
            const userDetails = await getUserDetailsFromUserIds(functions_team.id_team);
            const payload = {
                users: userDetails,
                timestamp: new Date(),
                event: "Nuevo Usuario",
                company:info.compañia,
                name: info.nombre,
                lastName: info.apellido,
            };
            // Enviar el soporte con el payload completo
            await sendSupport(payload);
            navigate('/kits/lista');
            toast.success('Orden creado con exito');
        } catch (error) {
            toast.error("Error creando la orden: ", error);
        }
        setLoading(false);
    };

    //---------------------------------------------------------------------------------------------------------------------------------
    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!kits) return <p>No hay información en este kit</p>;
    return (
        <>
            {open && (
                <div className="wimdons-emerge-detail">
                    <section className="emerge-detail">
                        <div className="emerge-detail-img">
                            <img alt={detail.name}  src={detail.img}/>
                        </div>
                        <div className="emerge-detail-contect">
                            <h2 className="subtitle3">{detail.name}</h2>
                            <div className="text-emerge">
                                <h4>Categoría: {detail.category}</h4>
                                <p><span>Marca:</span> {detail.brand}</p>
                                <p><span>Descripción:</span> {detail.description}</p>
                            </div>
                            <div className="emerge-detail-button">
                                <button className="button-delete" onClick={handleDetailClose}>
                                    <BiX /> 
                                    Cerrar 
                                </button>
                                {(!opendetail === true) && <button className="button-new" onClick={() => handleAdds(idProduct)}>
                                    <BiPlus />
                                    Agregar
                                </button>}
                            </div>
                        </div>
                    </section>
                </div>
            )}
            <div className="windons-two">
                <div className="windons-title-section">
                    <div className="title-info">
                        <h2 className="title2">{name}</h2>
                    </div>
                    <div></div>
                    <Link className='button-new' to="/kits/lista"><BiChevronLeft />Volver</Link>
                </div>
                <div className="windons-section-info">
                    <section className="section-info-two  seleccione-group">
                        <div className="section-title">
                            <div>
                                <label>Productos asignados</label>
                                <input 
                                type="text" 
                                placeholder="Buscar productos..." 
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <h2>{products.length - selectedProducts.length}</h2>
                        </div>
                        {products.length - selectedProducts.length !==0
                            ?(
                                <div className="list-info">
                                    {filterAsign&& filterAsign.filter(product => !selectedProducts.includes(product.id)).map(product => (
                                        <div key={product.id}  className="item-produc">
                                            <div className="img-item-product">
                                                <div className="avatar-img" onClick={() =>  handleDetail(product.id)} > 
                                                    <img alt={product ? product.name : ''}  src={product ? product.img : ''} />
                                                </div>
                                            </div>
                                            <button onClick={() => handleAdd(product.id)}>
                                                <p className="name">{product.name}</p>
                                                <p>Categoría: {product.category}</p>
                                                <p>SKU: {product.SKU}</p>
                                                <p>Marca: {product.brand}</p>
                                            </button >
                                            <BiPlus onClick={() => handleAdd(product.id)} />
                                        </div>
                                    ))}
                                </div>
                            ):(
                                <div className="empty-form">
                                    <img className="img-shofi-loanding" alt="Quiz Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031141348qHNT%20(1).png?alt=media&token=564826ec-6b61-4f17-b779-886b8e5d4cd7&_gl=1*1utmy1v*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc2MTY4NS4zNDYuMS4xNjk4NzYxNzQ4LjYwLjAuMA.." />
                                    <h2 className="title-shofi-loanding">Es hora de aceptar y esperar en casa tus equipos.</h2>
                                </div>
                            )}
                    </section>
                    <section className="section-info-two   seleccione-employe">
                        <div className="section-title">
                            <div>
                                <label>Productos seleccionados</label>
                                <input 
                                type="text" 
                                placeholder="Buscar productos..." 
                                value={searchTermG}
                                onChange={(e) => setSearchTermG(e.target.value)}
                                />
                            </div>
                            <h2>{selectedProducts.length}</h2>
                        </div>
                        {selectedProducts.length!==0?(
                            <div className="list-info">
                                {selectedProducts
                                    .map(productId => products.find(product => product.id === productId))
                                    .filter(product => product.name.toLowerCase().includes(searchTermG.toLowerCase()))
                                    .map((product, index) => (
                                        <div key={index} className="item-produc-selec">
                                            <div className="img-item-product">
                                                <div className="avatar-img" onClick={() => handleNoDetail(product.id)}>
                                                    <img alt={product.name} src={product.img} />
                                                </div>
                                            </div>
                                            <div>
                                                <p className="name">{product.name}</p>
                                                <p>{product.category}</p>
                                                <p>{product.SKU}</p>
                                                <p>Marca: {product.brand}</p>
                                            </div>
                                            <button onClick={() => handleDelete(product.id)}><BiX /></button>
                                        </div>
                                    ))
                                }
                            </div>
                        ):(
                            <div className="empty-form">
                                <img className="img-shofi-loanding" alt="Quiz Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2FPJ_To_do_Checklist-PhotoRoom.png-PhotoRoom.png?alt=media&token=af91c841-59f3-475c-ab05-7ad9334ca226&_gl=1*hcsaao*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNDI3LjEuMC4w" />
                                <h2 className="title-shofi-loanding">Comineza a seleccionar los articulos de trabajo que necesites para ser dotados.</h2>
                                <button className='button-new' onClick={selectAll}><BiCabinet/>Seleccionar todos</button>
                            </div>
                        )}
                    </section>
                </div>
                <div className="container-button">
                    <div></div>
                    {selectedProducts.length!==0&&<button className='button-new' onClick={handleSave}><BiSave />Aceptar</button>}
                </div>
            </div>
        </>
    );
}
