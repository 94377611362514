import "./Politcas.scss"
// Secciones componentes de Términos y condiciones
export const TerminosSiigoPOS = () => {
    return ( 
        <div className="main-politics">
            <section className="contect-politycs">
                <h1 className="title">Términos y condiciones del “Beneficio Siigo – SHOFI venta equipos para facturar con Siigo POS</h1>
                <h2 className="subtitle3">Beneficio:</h2>
                <p>El beneficio Siigo – SHOFI venta equipos para facturar con Siigo POS es un beneficio (en adelante el “Beneficio”) otorgado por SHOFI S.A.S identificada con 901.071.882-1 (en adelante “SHOFI”) o por cualquier otra sociedad del grupo que en su momento determine SHOFI S.A.S donde se busca ofrecer una solución todo incluido permitiendo a potenciales clientes que no demoren o pospongan la contratación del SaaS Siigo POS por falta de Hardware, ofreciendo precios diferenciales en el Sitio Web: 
                    <a href="https://www.goshofi.com/aliados/siigoPos">https://www.goshofi.com/aliados/siigoPos</a>, en los términos descritos en estos Términos y Condiciones.
                </p>
                <h2 className="subtitle3">El Beneficio que se otorgará a los usuarios de la plataforma Siigo es:</h2>
                <p>Equipos compatibles con Siigo POS con hasta un 30% de descuento en comparación con los precios del mercado.</p>
                <img alt="imagensiigo" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2Fterminos%2FIMGEN.jpg?alt=media&token=692f35e6-ca55-4351-be8f-34721f2a3579"/>
                <h2 className="subtitle3">Detalles del Servicio:</h2>
                <p>
                    <ul>
                        <li>El valor no incluye IVA.</li>
                        <li>El valor no incluye la logística.</li>
                        <li>Garantía de 1 año por falla de fábrica.</li>
                        <li>Asistencia Remota para Siigo POS al Número 315 2943605.</li>
                        <li>Asistencia Remota Gratuita para mal funcionamiento de los equipos.</li>
                    </ul>
                </p>
                <p>
                    Los usuarios que cumplan con las condiciones descritas en estos Términos y Condiciones se denominarán "Participantes" y podrán acceder al beneficio ingresando a la página 
                    <a href="https://www.goshofi.com/aliados/siigoPos">https://www.goshofi.com/aliados/siigoPos</a>, donde podrán diligenciar su información de contacto para solicitar un presupuesto o agendar una cita con el área comercial de SHOFI.
                </p>
                <p>La participación en el Beneficio implica el conocimiento y la aceptación total e incondicional de los presentes Términos y Condiciones así como los Términos y Condiciones de Uso de SHOFI los cuales se pueden encontrar en la siguiente URL: 
                    <a href="https://www.goshofi.com/tratamiento_de_datos_personales">https://www.goshofi.com/tratamiento_de_datos_personales</a>.
                </p>
                <h2 className="subtitle3">Requisitos del beneficio:</h2>
                <p>
                    Los requisitos para obtener el Beneficio son, además de cumplir con las demás condiciones previstas en los Términos y Condiciones de SHOFI, (i) registrarse por primera vez como usuario del Sitio Web, (ii) ser usuario de la plataforma Siigo, (iii) recibir la comunicación vía correo electrónico, mensaje de WhatsApp o por el aplicativo Siigo en la cual se haya informado que son elegibles para acceder al Beneficio.
                </p>
                <p>El Beneficio no es redimible en dinero, no es acumulable con otras promociones y es personal e intransferible.</p>
                <h2 className="subtitle3">Vigencia del beneficio:</h2>
                <p>El Beneficio será válido para las cuentas que se vinculen desde las 00:00 horas del 1 agosto del 2024 y que cumplan con los requisitos previstos en estos Términos y Condiciones.</p>
                <h2 className="subtitle3">Condiciones generales del beneficio:</h2>
                <p>
                    <ul>
                        <li>La participación de los Participantes implica el pleno conocimiento y aceptación de los Términos y Condiciones de SHOFI, así como los de este Beneficio y los requisitos para participar en el mismo.</li>
                        <li>SHOFI se reserva el derecho de modificar, ampliar y/o aclarar estos Términos y Condiciones, así como de interrumpir o suspender el Beneficio en cualquier momento durante el Plazo.</li>
                        <li>El Beneficio ofrecido es de carácter personal e intransferible, válido únicamente para los usuarios registrados en la plataforma Siigo.</li>
                        <li>Los servicios promocionados por Siigo son 100% virtuales o remotos.</li>
                        <li>Quienes accedan a la promoción u oferta descrita deberán aceptar las condiciones de uso del servicio promocionado publicadas en 
                            <a href="https://www.goshofi.com/tratamiento_de_datos_personales">https://www.goshofi.com/tratamiento_de_datos_personales</a> y la política de privacidad de Siigo publicada en 
                            <a href="https://www.siigo.com/politicas-de-uso/">https://www.siigo.com/politicas-de-uso/</a>.
                        </li>
                        <li>Siigo es un software contable que facilita el acceso de pequeñas y medianas empresas a productos y servicios ofrecidos por plataformas de pago aliadas.</li>
                    </ul>
                </p>
                <h2 className="subtitle3">Territorio y/o país al cual aplica:</h2>
                <p>Aplica únicamente para Colombia.</p>
                <h2 className="subtitle3">Solicitudes, reclamaciones o quejas:</h2>
                <p>Si existen dudas, reclamaciones o quejas acerca de esta actividad, los clientes activos podrán consultar más a través de los canales de atención telefónicos señalados en la página web o a través del correo electrónico siigoservicios@siigo.com.</p>
            </section>
        </div>
    );
}
