import "./P_Section_1.scss";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { Kits_Item } from "../../molecules/Kits_Item/Kits_Item";

export const PSection1 = ({setLoading}) => {
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', '04Q1CsAxgVyIQtYb2ZBc');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);

    const settings = {
        dots: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
    };

    if (error) return <p>Error: {error.message}</p>;
    return (
        <section className="section-contect">
            <div className='carrucel-kits'>
                <h1 className="title-page-1p" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                <p className="subtitle-page-1p" dangerouslySetInnerHTML={{ __html: info.subtitle }}></p>
                {info.kits&&<div className='carrucel-item-kits'>
                    <Slider {...settings} className="carrucel-item-kits-2-slider">
                        {info.kits.map(kit => (
                            // eslint-disable-next-line react/jsx-pascal-case
                            <Kits_Item key={kit.numb} {...kit}/>
                        ))}
                    </Slider>
                </div>}
            </div>
        </section>
    );
}