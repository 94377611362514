import "./ListUsersOpen.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { BiSortDown , BiSortUp } from "react-icons/bi";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Link } from "react-router-dom";
import { ItenUsersOpen } from "../../molecules/ItenUsersOpen/ItenUsersOpen";

export const ListUsersOpen = ({setSelectedId, setIsEditorOpen, compañia ,info, setSortType, setSortDirection , setFilterRole, filterRole, handleChange , sortDirection, numberOfPages, displayItems, currentPage, setIsRegisterOpen}) => {
    const toggleRegister = () => {
        setIsRegisterOpen(true);
    };
    return (
        <div className="list-users">
            <div className="contect-list-users">
                <div className="encabezado-users-list">
                    <h2 className="title  title-equip-list">Equipo: {compañia}</h2>
                    <div className="encabezado-users-button">
                        <Link className='button-new2' to="/open/invitations/csv">
                            <MdOutlineCloudUpload />
                            Carga masiva
                        </Link>
                        <button className='button-new' onClick={toggleRegister}>
                            <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                            Crear
                        </button>
                    </div>
                </div>
                {info && (
                    <>
                        <div className="title-user-list">
                            <p>Avatar</p>
                            <div className="filter">
                                <button onClick={() => setSortType('nombre')}>Nombre</button>
                                <button onClick={() => setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))}>
                                    {sortDirection === 'asc' ? <BiSortDown />  : <BiSortUp />}
                                </button>
                            </div>
                            <p>
                                Email
                            </p>
                            <div> </div>
                            <div className="filter">
                                <select onChange={(e) => setFilterRole(e.target.value)}>
                                    <option value="">{!filterRole ? "Status" : filterRole}</option>
                                    {(filterRole !=="Pendientes")?<option value="Pendientes">Pendientes</option> : <></>}
                                    {(filterRole !=="Terminados")?<option value="Terminados">Terminados</option>: <></>}
                                    {(filterRole !=="")?<option value="">Todos...</option>: <></>}
                                </select>
                            </div>
                            <div className="filter">
                                Fecha
                            </div>
                        </div>
                        <div className="list">
                            {displayItems.map(users => (
                                <ItenUsersOpen key={users.id} {...users} setIsEditorOpen={setIsEditorOpen} setSelectedId={setSelectedId}/>
                            ))}
                        </div>
                        <div className="pagination-numb">
                            <Stack spacing={2}>
                                <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                            </Stack>
                        </div>
                    </>
                    
                )}
            </div>
        </div>
    );
}